<template>
    <v-app>

        <div>
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <h3>
                                Assignments
                            </h3>
                            <router-link :to="{name:'dashboard'}">
                                Dashboard
                            </router-link>
                            \
                            Assignments
                        </div>
                    </div>
                </div>
            </div>
            <div class="card mt-2 mb-2">
                <div class="card-body">
                    <div class="row bg-filter">
                        <v-col cols="12" sm="6" md="4">
                          <v-select label="Courses" :items="courses" item-text="title" item-value="id" outlined dense v-model="searchData.course_id"></v-select>
                        </v-col>
                      <v-col cols="12" sm="6" md="4">
                          <v-select label="Class" :items="student_classes" item-text="class" item-value="class_id" outlined dense v-model="searchData.class_id"></v-select>
                        </v-col>

                        <v-col cols="12" sm="6" md="4">
                            <v-menu
                                    v-model="from"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="searchData.start_from"
                                            label="Start date"
                                            prepend-inner-icon="mdi-calendar"
                                            prepend-icogetCalendarDatesn=""
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        v-model="searchData.start_from"
                                        @input="from = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="4"
                        >
                            <v-menu
                                    v-model="to"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="searchData.end_to"
                                            label="End date"
                                            prepend-icon=""
                                            prepend-inner-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        v-model="searchData.end_to"
                                        @input="to = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col
                                cols="12"
                                sm="6"
                                md="2"
                        >
                            <v-select
                                    label="Submission"
                                    outlined
                                    dense
                                    v-model="submission"
                                    item-text="name"
                                    item-value="value"
                                    :items="submission_status"
                                    @change="changeStatus"
                            >

                            </v-select>
                        </v-col>
                        <div class="col-md-2 my-auto">
                            <v-btn class="btn btn-primary text-white" depressed :loading="isBusy" @click="__get">
                                Search
                            </v-btn>
                            <!--                                    <button class="btn btn-secondary ml-2" @click="reset">Reset</button>-->
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="loading">
                <div class="row mt-5">
                    <div class="col-md-12">
                        <v-skeleton-loader
                                type="table"
                        ></v-skeleton-loader>
                    </div>
                </div>
            </div>
            <div class="card" v-if="!loading && assignments.length>0">
                <div class="col-md-12">
                    <div class="table-responsive">
                        <table class="table table-stripe">
                            <thead>
                            <th>Title</th>
                            <th>Course</th>
                            <!--                                    <th>Topic</th>-->
                            <!--                                    <th>Lesson</th>-->
                            <th>Due Date</th>
                            <!--                                    <th>Type</th>-->
                            <th>Status</th>
                            <th>Result</th>
                            <th>Mark obtained</th>
                            </thead>
                            <tbody>
                            <tr v-for="(assignment, index) of assignments" :key="index">
                                <td>
                                    <router-link
                                            :to="{ name:'assignment-summary',params:{assignment_id: assignment.id}}">
                                        {{`${assignment.title} `}}
                                    </router-link>
                                </td>
                                <td v-if="assignment.course_title">{{ assignment.course_title }}</td>
                                <!--                                        <td v-if="assignment.topic_title">{{ assignment.topic_title }}</td>-->
                                <!--                                        <td>{{ assignment.topic_lesson_title || 'N/A' }}</td>-->
                                <td>{{ assignment.due_date | moment("dddd, MMMM Do YYYY") }}</td>
                                <!--                                        <td>-->
                                <!--                                          {{ assignment.submission_type ? assignment.submission_type : 'N/A ' |-->
                                <!--                                            format_status.toUpperCase() }}-->
                                <!--                                        </td>-->
                                <td>
                                    <i class="fas fa-circle mr-2"
                                       :class="assignment.submission_status ?'dot-active':'dot-inactive'"></i>
                                    <span :class="assignment.submission_status ?'text-success':'text-danger'">{{assignment.submission_status ? assignment.submission_status.status.toUpperCase() : 'Pending Submission'}}</span>


                                </td>
                                <td>
                                    <span class="text-uppercase font-weight-bold"
                                          :class="assignment.submission_status.result=='pass' ?'text-success':'text-danger'">{{assignment.submission_status.result ? assignment.submission_status.result : 'N/A'}}</span>


                                </td>
                                <td>
                                    <span class="text-uppercase font-weight-bold">{{assignment.submission_status.mark_obtained ? assignment.submission_status.mark_obtained : 'N/A'}}</span>


                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row" v-if="assignments.length>0">
                        <div class="col-12 text-center">
                            <b-pagination
                                    @input="__get"
                                    v-model="page"
                                    :total-rows="total"
                                    :per-page="perPage"
                                    first-number
                                    last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="!loading && assignments.length<1">
            <div class="col-md-12 text-center">
                <v-card class="p-20">
                    <img src="@/assets/media/images/no-data-found.png">
                    <h5 class="mt-10">No assignment available. Assignments will appear here once added by teacher.</h5>
                </v-card>
            </div>
        </div>
    </v-app>
</template>
<script>
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";
    import StudentMixin from "@/mixins/StudentMixin";
    import StudentSettingService from "@/core/services/user/student/StudentSettingService";
    const studentSettingService = new StudentSettingService();
    export default {
        name: "assignment",
        mixins: [AssignmentMixin, StudentMixin],
        data() {
            return {
              courses: [],
                closeOnClick: true,
                from: false,
                to: false,
                searchData: {
                    start_from: null,
                    end_to: null,
                    is_submitted: 0,
                },
                submission: 'not_submitted',
                submission_status: [
                    {
                        name: 'Submitted',
                        value: 'submitted'
                    },
                    {
                        name: 'Not submitted',
                        value: 'not_submitted'
                    },
                    {
                        name: 'All',
                        value: 'all'
                    }
                ],
                loading: true,
                assignments: []
            };
        },
        mounted() {
            this.__get();
            this.getGrades()
          this._getStudentCourses()
        },
        methods: {
            __get() {
                this.getAssignments(this.searchData)
            },
            changeStatus() {
                if (this.submission == 'all'){
                    delete this.searchData.is_submitted;
                    this.getAssignments(this.searchData)
                }
                else
                    this.submission === 'not_submitted' ? this.searchData.is_submitted = false : this.searchData.is_submitted = true;
            },
          getGrades() {
            this.loading = true;
            studentSettingService.getGrades(this.search).then(response => {
              this.courses = response.data.data;
              this.loading = false;
            }).catch(error=>{
              console.log(error)
            }).finally(()=>{
              this.loading = false;
            });
          },
        }
    };
</script>
